import Vue from 'vue'
import Vuex from 'vuex'
import APIResolver from "../../Lib/APIResolver";
import {i18n} from "@/plugins/vuetify";
import router from "@/router";
// import {EventBus} from "@/event-bus";
import {ajaxGet, ajaxPost} from "../../Lib/httpUtilities";
import axios from "axios";

Vue.use(Vuex)
/*eslint-disable*/
export default new Vuex.Store({
    state: {
        languages: ['en', 'fa'],
        profile: JSON.parse(localStorage.getItem('profile')),
        token: localStorage.getItem('token'),
        isLoggedIn: localStorage.getItem('isLoggedIn'),
        preflight: localStorage.getItem('preflight'),
        cart: null,
        selectedSessionWallet: null,
        selectedCycle: null,
        messages: [],
        bc_code: localStorage.getItem('bc_code'),
        contacts: [],
        tempProfile: {},
        platform: localStorage.getItem('platform'),
        currency: null,
        loading: false,
        canSubmit: false,
        sessions: null,
        activeConversion: null,
        doctor: null,
        App_url: process.env.VUE_APP_URL,
        testAssetUrl: 'https://psyback.simiaroom.com',
        selectedTime: null,
        errors: {},
        location: null,
        notRedirected: [
            'preorder',
            'testStepper',
            'onboarding',
            'match',
            'ResetPassword',
            'socialLogin',
            'login',
            'onboarding_new',
            "calender",
            "turkish_campaign"
        ],
        test: null,
        guest_participant_id: null,
        gpt_fallow_up_question: null,
        gpt_response: null,
        skypePreSession: null,
    },
    mutations: {
        SET_SKYPE_PRE_SESSION(state, payload) {
            state.skypePreSession = payload
        },
        SET_GUEST_PARTICIPANT_ID(state, payload) {
            state.guest_participant_id = payload
        },
        SET_TEST(state, payload) {
            state.test = payload
        },
        SET_ACTIVE_CONVERSION(state, payload) {
            state.activeConversion = payload
        },
        SET_CAN_SUBMIT(state, payload) {
            state.canSubmit = payload
        },
        SET_MESSAGES(state, payload) {
            if (payload.data.length != 0) {
                state.messages.unshift(...payload.data.reverse());
            } else {
                state.messages = []
            }
        },
        SET_SELECTED_SESSION_WALLET(state, payload) {
            state.selectedSessionWallet = payload
        },
        SET_LOCAL(state, payload) {
            localStorage.setItem('local', payload)
            i18n.locale = payload;
        },
        SET_PROFILE(state, payload) {
            state[payload.key] = payload.data
            localStorage.setItem('bc_code', payload.data.bc_code)
            localStorage.setItem('profile', payload)
        },
        SET_TOKEN(state, payload) {
            state[payload.key] = payload.data
        },
        SET_JWT_TOKEN(state, payload) {
            state[payload.key] = payload.data
        },
        SET_SESSIONS(state, payload) {
            state.sessions = payload.data
        },
        SET_LOGIN(state) {
            state.isLoggedIn = true
            localStorage.setItem('isLoggedIn', true)
        },
        SET_PREFLIGHT(state, payload) {
            state[payload.key] = payload.data
            localStorage.setItem('preflight', payload.data)
        },
        SET_CART(state, payload) {
            state[payload.key] = payload.data[0]
        },
        SET_SELECTED_CYCLE(state, payload) {
            state.selectedCycle = payload
        },
        SET_CONTACTS(state, payload) {
            state[payload.key] = payload.data
        },
        SET_PLATFORM(state, payload) {
            state[payload.key] = payload.data
            state.currency = payload.data.currency;
            localStorage.setItem('platform', payload.data)
        },
        SET_LOCATION(state, payload) {
            state[payload.key] = payload.data
        },
        setCart(state, data) {
            state.cart = data;
        },
        SET_CURRENCY(state, payload) {
            state[payload.key] = payload.data
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        setToken(state, token) {
            state.token = token.token;
            localStorage.setItem('token', token.token);
            axios.defaults.headers.common['Authorization'] = token.token;
        },
        SET_DOCTOR(state, payload) {
            state.doctor = payload
        },
        SET_PROFILE_EMPTY(state) {
            state.profile = null
        },
        SET_SELECTED_TIME(state, payload) {
            state.selectedTime = payload
        }
    },
    actions: {
        setSkypePreSession({commit}, payload) {
            commit('SET_SKYPE_PRE_SESSION', payload)
        },
        setGuestParticipantId({commit}, payload) {
            commit('SET_GUEST_PARTICIPANT_ID', payload)
        },
        setTest({commit}, payload) {
            commit('SET_TEST', payload)
        },
        setAlertErrors(state, errors) {
            state.errors = errors;
        },
        setProfileEmpty({commit}) {
            commit("SET_PROFILE_EMPTY")
        },
        setActiveConversion({commit}, payload) {
            commit("SET_ACTIVE_CONVERSION", payload)
        },
        setCanSubmit({commit}, payload) {
            commit('SET_CAN_SUBMIT', payload)
        },
        generalRequest(context, payload) {
            return new APIResolver().sendRequest(payload.type, payload.name, payload.paramsQuery, payload.body, payload.headers)
        },
        setLocal({commit}, payload) {
            commit('SET_LOCAL', payload)
        },
        pushMessage({state}, payload) {
            state.messages.push(payload)
        },
        gotMessages({state}, payload) {
            let activeContact = state.contacts.find(item => item.id == payload.user_id)
            activeContact.hasMesseages = true
            state.contacts.sort()
        },
        logout({state}, redirect = true) {
            let removeStorage = ['token', 'isLoggedIn', 'preflight', 'profile', 'bc_code', 'sessions', 'cart', 'webengage_login']
            localStorage.setItem('isLoggedIn', 'false')
            removeStorage.forEach((item) => {
                localStorage.removeItem(item)
                state[item] = null
            })
            if (redirect) {
                router.push({name: 'login'})
            }
        },
        getCart({commit, state}) {
            return ajaxGet('cart' + '?preflight=' + state.preflight).then((resp) => {
                let cart = JSON.parse(JSON.stringify(resp.data[0]));
                commit('setCart', cart);
                return cart;
            })
        },
        addToCart: ({commit, state}, payload) => {
            let addone = false;
            // eslint-disable-next-line no-prototype-builtins
            if (payload.hasOwnProperty('preorder')) {
                addone = true;
            }
            return ajaxPost("cart", {
                package_id: payload.id,
                addone: addone,
                preFlight: state.profile
            }).then((data) => {
                commit('setCart', data.data[0]);
                return data;
            }).catch((resp) => {
                if (resp.response.status == 400 && resp.response.data.data.cart) {
                    commit('setCart', resp.response.data.data.cart);
                }
            });
        },
        cartRemoveItem({commit}, payload) {
            return ajaxPost('cart/remove', payload).then((resp) => {
                commit('setCart', resp.data[0]);
                return resp;
            })
        },
        sendOrder(order) {
            return ajaxPost("saveorder", order).then(data => {
                return (data);
                // return data;
            })
        },
        validateCoupon({commit,}, payload) {
            return ajaxPost('validateCoupon', payload).then((resp) => {
                commit('setCart', resp.data['cart']);
                return resp;
            })
        },
        setLoadingStatus({commit}, payload) {
            commit('SET_LOADING', payload)
        },
        setCart({commit}, payload) {
            commit('SET_CART', payload)
        },
        processPaymentRedirectShopify() {
            return ajaxGet(`shopify/getSuccessfulPaymentRedirectUrl`, '', {}).then((resp) => {
                return resp;
            })
        },
        processPaymentRedirectRevolut() {
            return ajaxGet(`revolut/getSuccessfulPaymentRedirectUrl`, '', {}).then((resp) => {
                return resp;
            })
        },
        postSurveyAnswers({state}, payload) {
            return ajaxPost("user/onboarding-survey", payload);
        },
        clearMessages({state}) {
            state.messages = []
        },
        setDoctor({commit}, payload) {
            commit("SET_DOCTOR", payload)
        },
        selectedTime({commit}, payload) {
            commit("SET_SELECTED_TIME", payload)
            // console.log(payload)
        },
        sendCCAndGetFallowUpQuestion({state}, payload) {
            return axios.post('https://gptcraft.simiaroom.com/api/v1/instruction/3/send', payload, {
                headers: {
                    'X-USER-ID': state.profile.id
                }
            })
        },
        getResultGPT({state}, payload) {
            return axios.post('https://gptcraft.simiaroom.com/api/v1/instruction/4/answer', payload, {
                headers: {
                    'X-USER-ID': state.profile.id
                }
            })
        },
        getGPTConversations({state}, payload) {
            return axios.get(`https://gptcraft.simiaroom.com/api/v1/conversation/${payload.userId}/all?page=${payload.page}`)
        },
        getGPTConversationMessage({state}, payload) {
            return axios.get(`https://gptcraft.simiaroom.com/api/v1/conversation/${payload.userId}/${payload.conversation_id}`)
        },
        setGPTResponse({state}, payload) {
            state.gpt_response = payload.response
            state.gpt_fallow_up_question = payload.fallow_up
        },
        // getPreSessionPackage({state},payload){
        //     return ajaxPost()
        // }
    },
    modules: {},
    getters: {
        getGuestParticipantId: state => state.guest_participant_id,
        getSelectedTime: state => state.selectedTime,
        getAppUrl: state => state.App_url,
        getDoctor: state => state.doctor,
        getActiveConversion: state => state.activeConversion,
        getSessions: state => state.sessions,
        getCanSubmit: state => state.canSubmit,
        getProfile: state => state.profile,
        getCart: state => state.cart,
        getSelectedSessionWalllet: state => state.selectedSessionWallet,
        getSelectedCycle: state => state.selectedCycle,
        getMessages: state => state.messages,
        getBcCode: state => state.bc_code,
        getContacts: state => state.contacts,
        getTempProfile: state => state.tempProfile,
        getPlatform: state => state.platform,
        userIsLoggedIn: state => state.isLoggedIn,
        getErrors: state => state.errors,
        getSkypePreSession: state => state.skypePreSession,
        getUserInfo: (state) => {
            return state.profile
        },
        getUserCurrency: state => state.currency,
        getCartItems: state => {
            if (state.cart != null) {
                return state.cart.items;
            } else {
                return [];
            }
        },
        getIsLoggedIn: state => state.isLoggedIn,
        getLoading: state => state.loading,
        getLocation: state => state.location,
        getTest: state => state.test,
        getGPTResponse: state => state.gpt_response,
        getGPTFallow_up: state => state.gpt_fallow_up_question,
        testAssetUrl: state => state.testAssetUrl
    }
})
