export default [
    {
        name: "login",
        path: 'login',
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_TOKEN',
                key: 'token',
                dataPath: 'data.token',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
            {
                name: 'SET_LOGIN',
                key: null,
                data: true,
                localStoragePersist: false,
                debug: false
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "register",
        path: 'register',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "login-methods",
        path: 'login-methods',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "send-whatsapp-otp",
        path: 'send-whatsapp-otp',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "submit-whatsapp-otp",
        path: 'submit-whatsapp-otp',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "checkUser",
        path: 'checkUser',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "submit_telegram_token",
        path: 'user/attach-telegram',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },

    {
        name: "getProfile",
        path: 'user/profile',
        method: "get",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_PROFILE',
                key: 'profile',
                dataPath: 'data.profile',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "checklogin",
        path: 'checkLogin',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },

    {
        name: "editProfile",
        path: 'user/profile',
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_PROFILE',
                key: 'profile',
                dataPath: 'data.profile',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "demoGraphics",
        path: 'user/demographics',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },

    {
        name: "SubmitDemoGraphics",
        path: 'user/demographics',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "psy-register",
        path: 'psy-register',
        method: "post",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_JWT_TOKEN',
                key: 'token',
                dataPath: 'data.jwt',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "forgetPassword",
        path: 'forgotPassword',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "ChangePassword",
        path: 'changepassword',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "platformInfo",
        path: 'platformInfo',
        method: "get",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_PLATFORM',
                key: 'platform',
                dataPath: 'data.platform',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
            {
                name: 'SET_CURRENCY',
                key: 'currency',
                dataPath: 'data.currency',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
            {
                name: 'SET_LOCATION',
                key: 'location',
                dataPath: 'data.location',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },


]