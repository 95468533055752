<template>
  <v-app>
    <v-main class="pa-0 ma-0">
      <div class="fill-height">
        <div class="fill-height">
          <router-view></router-view>
        </div>
        <template v-if="snackbar.visibility">
          <v-fade-transition>
            <v-snackbar bottom :right="$vuetify.breakpoint.mdAndUp" v-model="snackbar.visibility"
                        :color="snackbar.color" class="text-center"
                        :timeout="snackbar.timeout">
              <div class="d-flex justify-space-between" :id="`${snackbar.id}_parent`">
                <div :id="snackbar.id">
                  {{ snackbar.title }}
                </div>
                <div @click="snackbar.visibility = false" style="cursor: pointer" v-ripple>
                  <v-icon>mdi-close</v-icon>
                </div>
              </div>
            </v-snackbar>
          </v-fade-transition>
        </template>
        <div :class="loader ? 'loaderBackArea' : ''" id="fill-it" :style="!loader && 'display:none'">
          <Loader :loader="loader"/>
        </div>
      </div>
    </v-main>
    <!--    <component :is="langeStyle[lang]"></component>-->
  </v-app>
</template>


<script>
// import EventBus from "../Lib/EventBus";

/*eslint-disable*/
import Fa from "@/components/Lang/fa";
import En from "@/components/Lang/en";
import Loader from "@/components/Main/Loader";
import EventsMixins from "@/mixins/EventsMixins";

export default {
  name: 'App',
  components: {Loader},
  mixins: [EventsMixins],
  // components: {En, Fa},
  data: () => ({
    loader: false,
    snackbar: {
      visibility: false,
      color: 'green',
      title: 'test',
      timeout: 5000,
      id: null,
    },
    notRedirected: [
      'preorder',
      'testStepper',
      'onboarding',
      'match',
      'ResetPassword',
      'socialLogin',
      'login',
      'onboarding_new',
      "calender",
      "turkish_campaign",
      "interpretationTest",
      "LoginWithToken",
      'TelegramInitialize'
    ],
    langeStyle: {
      fa: 'Fa',
      en: 'En',
    },
  }),
  computed: {
    classForLoader() {
      return this.loader ? 'blurBackGround' : 'blurBackGroundNone'
    },
    lang() {
      return localStorage.getItem('local');
    }
  },
  watch: {
    '$route'() {
      window.scrollTo({top: 0})
    },
    '$route.params.lang'() {
      this.bindLangClass()
    }
  },
  methods: {
    handleLoaderSize() {
      this.$nextTick().then(() => {
        let element = document.getElementById('fill-it')
        element.style.height = window.innerHeight + 'px';
        element.style.width = window.innerWidth + 'px';
      })
    },
    bindLangClass() {
      var body = document.querySelector('body');
      if (this.$route.params.lang) {
        switch (this.$route.params.lang) {
          case 'en':
            body.classList.add('en_body');
            break;
          case 'fa':
            body.classList.add('fa_body');
            break;
          default :
            body.classList.add('fa_body');
            break;
        }
      } else {
        body.classList.add('fa_body');
      }
      // Add the 'custom-font' class to the body
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleLoaderSize)
    this.handleLoaderSize()

    this.$onEvent('notify', ({title = "", color = 'black', id = null}) => {
      this.snackbar.visibility = false
      this.$nextTick().then(() => {
        this.snackbar = {
          visibility: true,
          color: color,
          title: title,
          id: id,
          timeout: 5000
        }
      })
    })
    this.$onEvent('setLoading', (val) => {
      this.$store.dispatch('setLoadingStatus', val)
      this.loader = val
      setTimeout(() => {
        this.loader = false
      }, 6000)
    })
  },
  created() {
    this.bindLangClass()
    this.requesting('auth', 'platformInfo')
    setTimeout(() => {
      this.requesting('auth', 'checklogin').catch((resp) => {
        if (resp.response.hasOwnProperty('status')) {
          if (resp.response.status == 401) {
            this.$store.dispatch('logout', false)
            if (!this.notRedirected.find(item => item == this.$route.name)) {
              this.$router.push({
                path: '/fa/login'
              })
            }
          } else {
            if (resp.response.data.data.hasOwnProperty('preflight')) {
              let payload = {
                key: 'preflight',
                data: resp.response.data.data.preflight
              }
              this.$store.commit('SET_PREFLIGHT', payload)
            }
          }
        }
      }).finally(() => {
        this.requesting('cart', 'getCart')
      })
    }, 2000)

    //
    // setTimeout(() => {
    //   try {
    //     console.log(ali)
    //     // Code that might throw an error
    //   } catch (error) {
    //     console.log(error )
    //     // The @sentry/vue integration will automatically capture this error
    //   }
    // }, 2000)


    if (this.$store.getters.getIsLoggedIn) {
      this.requesting('auth', 'getProfile').then((resp) => {
        let profile = resp.data.profile
        this.WebengageSetUserRequiredFiled(profile)
        if (this.$route.meta.checkRequiredFiled) {
          // if ((profile.requiredFields.length != 0 || profile.cycles.length == 0) && !profile.leadUser) {
          //   if (this.$route.name != "onboarding_new") {
          //     this.$router.push({name: "onboarding_new"})
          //   }
          //   return;
          // }
          // if (profile.requiredFields.length != 0 && profile.leadUser) {
          //   if (this.$route.name != "userOnboarding") {
          //     this.$router.push({name: "userOnboarding"})
          //   }
          //   return;
          // }
        }
      });
    }
  },

};
</script>

<style>

.v-application a {
  color: inherit;
}

.advance .hooper-indicator {
  background: #ddd !important;
  padding: 3px 7px;
}

/*.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {*/
/*  */
/*}*/

.v-list-item__content .v-list-item__title {
  line-height: 1.5em !important;
}

.TodayNotation {
  width: 15px;
  height: 15px;
  background: #3CBE8B;
  border-radius: 50%;

}

.HasSessionNotation {
  width: 15px;
  height: 15px;
  background: #f6fff7 !important;
  border: 1px solid #3CBE8B;
  border-radius: 50%;
}

.HintLabel {
  font-size: 12px;
  margin-right: 5px;
}


.loaderBackArea {
  /*height: 100vh !important;*/
  /*overflow: hidden;*/
  position: fixed;
  /*overflow: scroll; */
  top: 0;
  left: 0;
  z-index: 99999;
  /*right: 0;*/
  /*width: 100%!important;*/
}


.blurBackGround {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  transition: all 0.1s ease;
}

.blurBackGroundNone {
  transition: all 0.3s ease;
}

.titleProfileSection {
  color: #4597DF;
  font-size: 18px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.en_arrow {
  transform: rotate(180deg);
  margin-left: 10px;
}

@media (min-width: 200px) and (max-width: 600px) {
  .genderIcon {
    width: 60px !important;
    height: 60px !important;
  }
}

@font-face {
  font-family: 'main';
  src: url("assets/fonts/Estedad-FD-v5.0/400-Estedad-FD-Regular.ttf");
}

@font-face {
  font-family: 'bold';
  src: url("assets/fonts/Estedad-FD-v5.0/600-Estedad-FD-SemiBold.ttf");
}

@font-face {
  font-family: 'semi-bold';
  src: url("assets/fonts/Estedad-FD-v5.0/500-Estedad-FD-Medium.ttf");
}

@font-face {
  font-family: 'en-num';
  src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'en-light';
  src: url("assets/fonts/Roboto-Light.ttf");
}

.en_light {
  font-family: en-light !important;
}

.en_body * {
  font-family: en-light !important;
  letter-spacing: 0px !important;
  scroll-behavior: smooth;
}

.fa_body * {
  /*direction: rtl !important;*/
  font-family: main !important;
  letter-spacing: 0px !important;
  scroll-behavior: smooth;
}

.en_num {
  font-family: en-num !important;
}


.phoneNumber input {
  direction: ltr !important;
}


/*@media (min-width: 200px) and (max-width: 600px) {*/
/*  body{*/
/*    width: auto !important;*/
/*    overflow: auto !important;*/
/*    overflow-x: hidden !important;*/
/*  }*/
/*}*/
body {
  /*width: 100%;*/
  /*overflow: hidden;*/
}

.fill-height {
  height: 100%;
}

.pmd-title-separator-center:after, .pmd-title-separator-right:after, .pmd-title-separator:after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background-color: #f58634;
  margin: 0.65rem 0 0 1rem;
}


.v-application {
}

.ltrInput .v-text-field__slot input {
  direction: ltr !important;
}

.googleBtn {
  width: 268px !important;
  /*height: 50px !important;*/
  border-radius: 10px !important;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: #000 !important;
  background: transparent !important;
  border: 1px solid #46b1a1;
}

.customInput {
  border-bottom: 1px solid #6d6d6d;
  display: flex;
}

/*.customInput  .theme--light.v-text-field > .v-input__control > .v-input__slot:before{*/
/*  border-style: none !important;*/
/*}*/

.genderIcon {
  background-color: #b9b9b9;
  mask-size: cover !important;
  width: 75px;
  transition: all 0.3s;
  height: 75px;
  cursor: pointer;
}

.ActiveGenderText {
  color: #6d5aac;
  font-weight: bold;
  transition: all 0.3s;
}

.activeGender {
  background: #6d5aac;
  transition: all 0.3s;
}

.menGender {
  -webkit-mask: url('assets/userOnboarding/men.svg') no-repeat center;
  mask: url('assets/userOnboarding/men.svg') no-repeat center;
}

.womenGender {
  -webkit-mask: url('assets/userOnboarding/women.svg') no-repeat center;
  mask: url('assets/userOnboarding/women.svg') no-repeat center;
}

.otherGender {
  -webkit-mask: url('assets/userOnboarding/other.svg') no-repeat center;
  mask: url('assets/userOnboarding/other.svg') no-repeat center;
}

.genderArea p {
  font-size: 13px;
  color: #6d6d6d;
  transition: all 0.3s;
  text-align: center;
}

.centering {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
</style>
